import { BookingStatus } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import BookingBuilder from './mockBooking';
import StaffBuilder from './mockStaff';
import { Bookings } from '../../src/types';
import { mockGroup } from './mockGroup';

export const mockEnrichedBookings = ({
  groups,
}: Partial<Bookings> = {}): Bookings => {
  return {
    groups: groups || [
      mockGroup({
        bookings: [
          BookingBuilder()
            .withVideoConference({ link: 'wix.com' })
            .withStaffInfo(StaffBuilder().build())
            .withService()
            .build(),
          BookingBuilder().build(),
        ],
      }),
      mockGroup({
        bookings: [
          BookingBuilder().withVideoConference({ link: 'wix.com' }).build(),
        ],
      }),
      mockGroup({
        bookings: [
          BookingBuilder()
            .withVideoConference({ link: 'wix.com', password: '123abc' })
            .build(),
        ],
      }),
      mockGroup({
        bookings: [
          BookingBuilder()
            .withPricingPlanName('plan name')
            .withStatus(BookingStatus.PENDING)
            .withService()
            .build(),
        ],
      }),
      mockGroup({
        bookings: [
          BookingBuilder()
            .withPricingPlanName('second plan name')
            .withStatus(BookingStatus.PENDING)
            .withService()
            .withStaffInfo(StaffBuilder().build())
            .build(),
        ],
      }),
    ],
  };
};
