import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const [state, setState] = getControllerState();
    const { api } = context;

    const historyData = await api.getEnrichedBookings({
      type: TabTypes.HISTORY,
      applicationState: state,
    });

    setState({
      historyData,
    });
  };
}
