import { navigateToBookingsServicePage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { CreateActionParams } from '../actions';
import { BookingDTO } from '../../types';

export type ClickOnViewMoreDetails = (args: { booking: BookingDTO }) => void;

export function createClickOnViewMoreDetailsAction({
  context,
}: CreateActionParams): ClickOnViewMoreDetails {
  return async ({ booking }) => {
    const { flowAPI } = context;
    const { wixCodeApi } = flowAPI.controllerConfig;
    const slug = booking.service?.mainSlug?.name;

    try {
      if (slug) {
        await navigateToBookingsServicePage(wixCodeApi, {
          serviceSlug: slug,
        });
      } else {
        throw new Error('Service slug is missing');
      }
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
