import { listTransactionsForMultipleOrders } from '@wix/ambassador-ecom-v1-order-transactions/http';
import { ApiContext } from '../../types';
import { ListTransactionsForMultipleOrdersResponse } from '@wix/ambassador-ecom-v1-order-transactions/types';

export interface ListTransactionsForMultipleOrdersArgs {
  orderIds: string[];
}

export const listTransactionsForMultipleEcomOrders = async ({
  orderIds,
  flowAPI,
}: ListTransactionsForMultipleOrdersArgs &
  ApiContext): Promise<ListTransactionsForMultipleOrdersResponse> => {
  const { httpClient } = flowAPI;
  return (
    await httpClient.request(
      listTransactionsForMultipleOrders({
        orderIds,
      }),
    )
  ).data;
};
