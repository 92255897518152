import { ActionsContext } from '../../core/actionsContext/contextFactory';
import { BookingStatus } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { ApplicationState, ApplicationStatus, TabTypes } from '../../types';
import { mockUserInfo } from '../../../__tests__/mocks/mockUserInfo';
import { mockSelectedTimezone } from '../../../__tests__/mocks/mockSelectedTimezone';
import { mockEnrichedBookings } from '../../../__tests__/mocks/mockEnrichedBookings';
import { mockSelectedTab } from '../../../__tests__/mocks/mockSelectedTab';
import { mockGroup } from '../../../__tests__/mocks/mockGroup';
import { mockService } from '../../../__tests__/mocks/mockService';
import BookingBuilder from '../../../__tests__/mocks/mockBooking';
import StaffBuilder from '../../../__tests__/mocks/mockStaff';
import { mockBusinessInfo } from '../../../__tests__/mocks/mockBusinessInfo';

export const createEmptyDummyState = (): ApplicationState => {
  return {
    status: ApplicationStatus.IDLE,
    selectedTab: mockSelectedTab({ type: TabTypes.UPCOMING }),
    environmentModes: {
      isEditorMode: true,
      isPreviewMode: false,
      isSiteMode: false,
    },
    businessInfo: mockBusinessInfo(),
    userInfo: mockUserInfo(),
    selectedTimezone: mockSelectedTimezone(),
    upcomingData: mockEnrichedBookings({
      groups: [],
    }),
  };
};

export const createUpcomingDummyState = ({
  flowAPI,
}: ActionsContext): ApplicationState => {
  const { t } = flowAPI.translations;
  const staffMember = t('app.my-bookings-widget.dummy.staff-member');
  const serviceName = t('app.my-bookings-widget.dummy.service-name');
  const serviceAddress = t('app.my-bookings-widget.dummy.service-address');

  const relatedStaff = StaffBuilder().buildWithName(staffMember).build();
  const groups = [
    mockGroup({
      bookings: [
        BookingBuilder()
          .withService(mockService({ name: serviceName }))
          .withVideoConference({ link: 'wix.com' })
          .build(),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withVideoConference({ link: 'wix.com' })
          .withLocationInfo(serviceAddress)
          .withStaffInfo(relatedStaff)
          .withCancelAllowed()
          .withBookAnotherAllowed()
          .withRescheduleAllowed()
          .withService(mockService({ name: `${serviceName} 1` }))
          .build(),
        BookingBuilder()
          .withVideoConference({ link: 'wix.com' })
          .withStaffInfo(relatedStaff)
          .withService(mockService({ name: `${serviceName} 2` }))
          .build(),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withService(mockService({ name: serviceName }))
          .withStatus(BookingStatus.PENDING)
          .build(),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withService(mockService({ name: serviceName }))
          .withVideoConference({ link: 'wix.com' })
          .build(),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withService(mockService({ name: serviceName }))
          .withStatus(BookingStatus.WAITING_LIST)
          .build(),
      ],
    }),
  ];

  return {
    status: ApplicationStatus.IDLE,
    selectedTab: mockSelectedTab({ type: TabTypes.UPCOMING }),
    environmentModes: {
      isEditorMode: true,
      isPreviewMode: false,
      isSiteMode: false,
    },
    businessInfo: mockBusinessInfo(),
    userInfo: mockUserInfo(),
    selectedTimezone: mockSelectedTimezone(),
    upcomingData: mockEnrichedBookings({
      groups,
    }),
  };
};
