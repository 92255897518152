import { Order, OrderTransactions } from '@wix/ambassador-ecom-v1-order/types';
import { ApiContext } from '../../types';
import { Bookings } from '../../../types';

export interface GetOrdersAndTransactionsArgs {
  bookings: Bookings;
}

export const getOrdersAndTransactions = async ({
  api,
  bookings,
}: ApiContext & GetOrdersAndTransactionsArgs): Promise<{
  orders?: Order[];
  transactions?: OrderTransactions[];
}> => {
  const bookingIds = bookings.groups.map(
    (group) => group.bookings[0].bookingId,
  );
  const orders = (await api.queryOrders({ bookingIds })).orders;
  const orderIds =
    orders?.filter((order) => !!order.id).map((order) => order.id!) || [];
  const transactions = orderIds.length
    ? (await api.listTransactionsForMultipleOrders({ orderIds }))
        .orderTransactions
    : [];

  return { orders, transactions };
};
