import { BusinessPropertyNames } from '../../../../api/types';
import {
  GetAggregatedInfoResponse,
  Property,
} from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import { defaultLocale } from '../../../../consts/locale';
import { BusinessTimezoneTypes, BusinessInfo } from '../../../../types';

export const mapResponseToBusinessInfo = (
  businessInfo: GetAggregatedInfoResponse,
): BusinessInfo => {
  const { locale, timeZone, formattedAddress } = businessInfo.infoView!;

  const businessProperties = new Map();

  businessInfo.customProperties!.forEach((property: Property) => {
    businessProperties.set(property.propertyName, property.value);
  });

  const allowTimezoneSwitch =
    businessProperties.get(BusinessPropertyNames.CLIENT_CAN_CHANGE_TIMEZONE) ===
    'true';

  const defaultTimezoneToUse =
    businessProperties.get(BusinessPropertyNames.DEFAULT_TIMEZONE_TYPE) ===
    BusinessTimezoneTypes.USER
      ? BusinessTimezoneTypes.USER
      : BusinessTimezoneTypes.BUSINESS;

  return {
    allowTimezoneSwitch,
    defaultTimezoneToUse,
    businessTimezone: timeZone!,
    dateRegionalSettingsLocale: locale || defaultLocale,
    formattedAddress,
  };
};
