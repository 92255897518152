import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';

export type GetUpcoming = () => void;

export function createGetUpcomingAction({
  context,
  getControllerState,
}: CreateActionParams): GetUpcoming {
  return async () => {
    const [state, setState] = getControllerState();
    const { api } = context;

    const upcomingData = await api.getEnrichedBookings({
      type: TabTypes.UPCOMING,
      applicationState: state,
    });

    setState({
      upcomingData,
    });
  };
}
