import { BOOKINGS_APP_DEF_ID } from '../../consts/applicationIdentities';
import { BookingsGroup } from '../../types';

export const isBookingPaidWithPricingPlan = (group: BookingsGroup): boolean => {
  const bookingLineItemId = group.order?.lineItems?.find(
    (lineItem) =>
      lineItem.catalogReference?.catalogItemId ===
        group.bookings[0].bookingId &&
      lineItem.catalogReference.appId === BOOKINGS_APP_DEF_ID,
  )?.id;

  if (bookingLineItemId) {
    return !!group.transaction?.payments?.find(
      (payment) =>
        payment.membershipPaymentDetails?.lineItemId === bookingLineItemId,
    );
  }

  return false;
};
